'use client'
import { Environment } from '@react-three/drei'
import { Suspense, useState } from 'react'
import { Forex } from '../components/Examples'
import { View } from '../components/View'
import Footer from '../footer'



export default function Page() {
    const [mouse, setMouse] = useState({
        x: (typeof window == "undefined") ? -1 : window.innerWidth / 2,
        y: (typeof window == "undefined") ? 0 : window.innerHeight / 2,
        deltaX: 0,
    })
    const onMove = (e) => {
        setMouse({ x: e.clientX, y: e.clientY, deltaX: e.movementX });
    }

    return (
        <div className='font-sans no-scrollbar flex h-screen min-h-screen flex-col justify-between overflow-visible overflow-x-hidden '




            onTouchMove={(e) => {
                setMouse({ x: e.touches[0].clientX, y: e.touches[0].clientY, deltaX: e.touches[0].clientX < window.innerWidth / 2 ? -1 : 1 })
            }}
            onMouseMove={onMove}

        >
            <Terms />
            <div className='relative overflow-hidden'>
                <View orbit={false} className='fixed left-0 top-0 -z-0 m-0 h-screen w-screen p-0  '>
                    <Suspense fallback={null}>
                        <Environment preset='apartment' ></Environment>
                        <Forex
                            resetMouse={() => { setMouse({ x: window.innerWidth / 2, y: window.innerHeight / 2, deltaX: 0 }) }}
                            mouse={mouse} scale={2} position={[0, -1.6, 0]} rotation={[0.0, -0.3, 0]} />
                    </Suspense>
                </View>
                <div className="hero fixed left-0 top-0 z-0 h-full w-full overflow-visible  bg-gradient-to-r from-cyan-950 to-blue-950">

                </div>
            </div>
            <div className="relative z-50 w-screen overflow-visible">
                <Footer policy={true}></Footer>

            </div>
        </div>
    )
}


const Terms = () => {
    return (
        <div className="font-sans relative z-20 mx-4 flex  items-center justify-center py-4 drop-shadow-lg lg:pt-10">

            <div className="w-full  max-w-5xl rounded border-8 border-blue-950 bg-black/80    text-white   shadow">
                <div className="flex flex-col items-center  bg-blue-950">
                    <div className="flex   flex-col items-center justify-center border-b border-dotted border-gray-200 py-4 text-center">
                        <h1 className=" pb-2 text-2xl font-bold">TERMS OF USE AND CONTITIONS</h1>
                        <p className=" max-w-xl    text-sm ">Last Updated On: February 12, 2024</p>
                    </div>
                    <div className="h-4"></div>

                </div>
                {/* Sections */}
                <div className="p-8 text-black">
                    <div className="space-y-6">
                        {/* Section 1 */}
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">IMPORTANT – PLEASE CAREFULLY READ AND UNDERSTAND OUR TERMS OF USE AND CONDITIONS OF SALE (“TERMS” OR “AGREEMENT”) BEFORE ACCESSING OR ACCESSING, USING, OR PLACING AN ORDER THROUGH OUR SITE. THESE TERMS CONTAIN DISCLAIMERS OF WARRANTIES AND LIMITATIONS OF LIABILITIES (SEE SECTIONS 15 AND 16). THESE TERMS FORM AN ESSENTIAL BASIS OF OUR AGREEMENT.</h2>
                            <p className="mb-4 text-gray-200">Your use of DFU-Solutions.com, including any sub-domains thereof, affiliated websites, and mobile applications (collectively, the “Site”), which are owned and maintained by Done-For-You Solutions, LLC (“Done-For-You Solutions,” “we,” “our,” “us”), are governed by the policies, terms, and conditions set forth below. Please read our terms carefully. We offer the Site, including all information, tools, products, and services available from the Site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here. By accessing, using, or placing an order over the Site, you agree to the terms set forth herein. If you do not agree to these terms and conditions in their entirety, you are not authorized to use the Site in any manner or form whatsoever.</p>
                        </section>

                        {/* Section 2 */}
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">THIS AGREEMENT CONTAINS ARBITRATION AND CLASS ACTION WAIVER PROVISIONS THAT WAIVE YOUR RIGHT TO A COURT HEARING, RIGHT TO A JURY TRIAL, AND RIGHT TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND IS THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW IN SECTION 17 OR IF YOU OPT-OUT. PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 17 BELOW WHICH DESCRIBES YOUR RIGHT TO OPT-OUT.</h2>

                            <p className="mb-4 text-gray-200">
                                You can review the most current version of the Terms at any time on this page (Terms and Conditions | Done-For-You Solutions). We reserve the right to update, change, or replace any part of these Terms by posting updates and/or changes to our Site. It is your responsibility to check this page periodically for changes. YOUR CONTINUED USE OF OR ACCESS TO THE SITE FOLLOWING THE POSTING OF ANY CHANGES CONSTITUTES BINDING ACCEPTANCE OF THOSE CHANGES.</p>
                        </section>

                        {/* Section 3 */}
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">1. Site Use</h2>

                            <p className="mb-4 text-gray-200">
                                Done-For-You Solutions’s Site, products, and services are intended for adults only. By using the Site and agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence. If you use the Site, you affirm that you have the legal capacity to enter into a binding contract with us, and have read this Agreement and understand and agree to its terms.</p>
                        </section>

                        {/* Section 4 */}
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">2. Changes to these terms</h2>
                            <p className="mb-4 text-gray-200">Done-For-You Solutions reserves the right to update, change, or replace any part of these Terms by posting updates and/or changes to our Site. You can review the most current version of these Terms at any time by visiting this page (Terms and Conditions | Done-For-You Solutions). It is your responsibility to check this page periodically for changes. Your continued use of, or access to, the Site following the posting of any changes constitutes your binding acceptance of those changes.</p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">3. Privacy and Security Disclosure</h2>
                            <p className="mb-4 text-gray-200">
                                Done-For-You Solutions’s Privacy Policy may be viewed at Privacy Policy | Done-For-You Solutions. The Privacy Policy is hereby incorporated into these Terms by reference and constitutes a part of this Agreement. Done-For-You Solutions reserves the right to modify the Privacy Policy at its sole discretion.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">4. No Investment Recommendations or Professional Advice</h2>
                            <p className="mb-4 text-gray-200">
                                Done-For-You Solutions does not provide personalized investment advice. Neither the Site nor any of Done-For-You Solutions’s products or services, are intended to provide tax, legal, insurance, or investment advice. None of the content provided on the Site or through any of Done-For-You Solutions’s products or services should be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for any security by Done-For-You Solutions or any third party. You alone are solely responsible for determining whether any investment, security or strategy, or any other product or service, is appropriate or suitable for you based on your investment objectives and personal and financial situation. You should consult a registered investment advisor, attorney, or tax professional regarding your particular financial situation, investing strategies, or specific legal or tax situation.

                                To the extent that any of the content published on the Site may be deemed to be investment advice or recommendations in connection with a particular security, such information is impersonal and not tailored to the investment needs of any specific person. You understand that an investment in any security is subject to a number of risks, and that discussions of any security published on the Site will not contain a list or description of relevant risk factors.

                                You understand that performance data is supplied by sources believed to be reliable, that the calculations on our Site are made using such data, and that such calculations are not guaranteed by these sources, the information providers, or any other person or entity, and may not be complete.

                                From time to time, our Site may reference prior articles and opinions that we have published. These references may be selective, may reference only a portion of an article or opinion, and are likely not to be current. As markets change continuously, previously published information and data may not be current and should not be relied upon.

                                All content on the Site is presented only as of the date published or indicated and may be superseded by subsequent market events or for other reasons. In addition, you are responsible for setting the cache settings on your browser to ensure you are receiving the most recent data.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">5. No Direct Lending</h2>
                            <p className="mb-4 text-gray-200">
                                Done-For-You Solutions is a financial education and training company. We do not offer any lending services, either personal or business. Your ability to obtain loans, including the amount, rate, and other terms, will ultimately depend on the determination of third party lenders, which is beyond our control. We do not guarantee that you will be qualified for or receive any particular offer from any lender.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">6. Securites & Investing Disclosure</h2>
                            <p className="mb-4 text-gray-200">

                                Stocks and options trading have large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest in the stocks and options markets. Don’t trade with money you can’t afford to lose.

                                Done-For-You Solutions does not represent that any account will or is likely to achieve profits or losses similar to those discussed on the Site. The past performance of any trading system or methodology is not necessarily indicative of future results. All trades, patterns, charts, systems, etc., discussed on the Site are for illustrative purposes only and not to be construed as specific advisory recommendations. Information contained on the Site is intended for informational purposes only.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">7. Intellectual Property</h2>
                            <p className="mb-4 text-gray-200">

                                The Site and the content contained herein, as well as all copyrights, including without limitation, the text, documents, articles, products, software, graphics, photos, sounds, videos, interactive features, services, links, User Submissions (as defined below), third-party apps, and any other content on the Site (“Content”) and the trademarks, service marks, and logos contained therein are the property of Done-For-You Solutions and its third-party licensors or providers. You may access and use the Content, and download and/or print out copies of any content from the Site, solely for your personal, non-commercial use. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein. You acknowledge that you do not acquire any ownership rights by using the Site. Done-For-You Solutions reserves all rights not expressly granted in and to the Site.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">8. User Conduct</h2>
                            <p className="mb-4 text-gray-200">
                                You may not use, copy, display, sell, license, decompile, republish, upload, post, transmit, distribute, create derivative works, or otherwise exploit Content from the Site to online bulletin boards, message boards, newsgroups, chat rooms, or in any other manner, without our prior written permission. Modification of the Content or use of the Content for any purpose other than your own personal, non-commercial use is a violation of our copyright and other proprietary rights, and can subject you to legal liability.

                                In addition, in connection with your use of the Site and its products or services, you agree not to:

                                - Restrict or inhibit any other visitor from using the Site, including, without limitation, by means of “hacking” or defacing any portion of the Site;

                                - Use the Site for any unlawful purpose;

                                - Express or imply that any statements you make are endorsed by us, without our prior written consent;

                                - Modify, adapt, sublicense, translate, sell, reverse engineer, decompile, or disassemble any portion of the Site;

                                - Disable, damage, or alter the functioning or appearance of the Site, including the presentation of advertising;

                                - “Frame” or “mirror” any part of the Site without our prior written authorization;

                                - Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to download, retrieve, index, “data mine,” “scrape,” “harvest,” or in any way reproduce or circumvent the navigational structure or presentation of the Site or its contents;

                                - Harvest or collect information about visitors to the Site without their express consent;

                                - Send unsolicited or unauthorized advertisements, spam, chain letters, etc. to other users of the Site; or

                                - Transmit any Content which contains software viruses or other harmful computer code, files, or programs.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">9. Account Registration</h2>
                            <p className="mb-4 text-gray-200">
                                In order to access some of the products or services of the Site, you will be required to create an account. By creating this account, you agree to the following:

                                - You may only maintain a single account;

                                - You may never share your account user name or password or knowingly provide or authorize access to your account;

                                - You may never use another user’s account without permission;

                                - When creating your account, you must provide accurate and complete information;

                                - You are solely responsible for the activity that occurs on your account, and you must keep your account password secure;

                                - You must notify us immediately of any breach of security or unauthorized use of your account; and

                                - You will be liable for any use made of your account or password and the losses of Done-For-You Solutions or others due to such unauthorized use. We will not be liable for your losses caused by any unauthorized use of your account.

                                Done-For-You Solutions has the right, in its sole discretion, to cancel your subscription or suspend your access to the Site.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">10. Automatic Billing and Cancellation Policy</h2>
                            <p className="mb-4 text-gray-200">
                                Some of Done-For-You Solutions’s products and services are provided on a subscription basis. If you purchase a subscription, the subscription will automatically renew at the then-current rate for the subscribed product or service, until you notify us of your decision to terminate your subscription. The most common renewal terms are twelve-month periods as identified on your itemized receipt. Prior to billing you for the renewal term, Done-For-You Solutions will email the address that you have on file for your account about the length and price of the renewal term.

                                By allowing the renewal to proceed, you have provided your electronic authorization for future charges against the credit card or PayPal account that you have on file. You further agree that your subscription fee(s) will be billed automatically at the beginning of each renewal period at the then-current rate (plus sale tax, if applicable) to the credit card(s) or PayPal account you have on file. If you have more than one card on file, the first card will be used. If you have more than one credit card on file, and one or more credit cards has expired, we will use the unexpired credit card.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">11. Refunds
                            </h2>
                            <p className="mb-4 text-gray-200">
                                Unless otherwise stated in writing with respect to particular Services offered for sale via the Site, Done-For-You Solutions abides by a strict, no refund policy. By accepting these Terms, You agree and understand that you are foregoing the right to claim any refund of fees paid for access and use of the Services offered via this Website. Please contact Customer Service if you have any questions regarding whether you may receive a refund for a particular service or offer.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">12. Payment Methods</h2>
                            <p className="mb-4 text-gray-200">
                                All charges are in U.S. Dollars. We accept U.S. issued credit and debit cards from Visa, MasterCard, American Express, and Discover and PayPal.

                                When placing an order online with a credit or debit card, you will need:

                                1) The address the card’s statement is sent to (billing address);

                                2) The card number and expiration date; and

                                3) The 3 or 4 digit code found only on the card (CVV2 code).

                                By submitting credit card information or other payment information to us, you represent and agree that: (i) you are fully entitled to use that card or account; (ii) if you choose a subscription-based purchase, that you will pay all payments for your subscriptions by the date due; (iii) all payment information provided is complete and accurate; (iv) you will be responsible for any credit card fees; and (v) that sufficient funds exist to pay us the amount(s) due.

                                We and our third party payment service providers may request, and we may receive, updated credit card information from your credit card issuer, such as updated card numbers and expiration date information when your credit card has expired. If such updated information is provided to us and our third-party payment service providers, we will update your account information accordingly, and will use such updated information to process payments for your subscription(s) if you signed up for subscription(s). Your credit card issuer may give you the right to opt out of providing vendors and third-party payment service providers with your updated credit card information. If you wish to opt out of your credit card’s updating service, you should contact your credit card issuer.

                                We are not responsible for any fees or charges that your bank or credit card issuer may apply. If your bank or credit card issuer reverses a charge to your credit card, we may bill you directly and seek payment by another method including a mailed statement.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">13. Social Media</h2>
                            <p className="mb-4 text-gray-200">
                                This section applies to everyone who interacts with our social media presence, including comment sections, feeds, and other elements of social media presence viewable on Facebook, Instagram, Twitter, YouTube, Google+, LinkedIn, or any of the many other available external third party social media platforms we may utilize (“Social Media Presence”).

                                Social media platforms are places of public information exchange, and you should have no expectation of privacy when using them. Specifically, neither these Terms nor our Privacy Statement apply to our Social Media Presence. The sites and platforms that host our Social Media Presence are not controlled by us and therefore have their own privacy policies and terms of use. The comments and opinions expressed by users on social media are theirs alone and do not reflect the opinions of Done-For-You Solutions. Comments that some would consider inappropriate or offensive may appear on our Social Media Presence and may remain there until they have been identified by us or called to our attention and we are able to work through the necessary procedures and technical processes to have them removed. If you see an offensive or inappropriate post or comment on our Social Media Presence, you should report it to the operator of the applicable site or platform using the procedures they have established for that purpose.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">14. Copyright Notice</h2>
                            <p className="mb-4 text-gray-200">
                                The Site maintains specific contact information provided below, including an e-mail address, for notifications of claimed infringement regarding materials posted to this Site. All notices should be addressed to the contact person specified below (our agent for notice of claimed infringement):
                                <br></br>

                                Notification of Claimed Infringement:
                                <br></br>

                                Done-For-You Solutions
                                <br></br>

                                7901 4th St N
                                <br></br>

                                STE 300
                                <br></br>

                                St. Petersburg, FL. 33702
                                <br></br>

                                Phone: +1 (817)-889-2359
                                <br></br>

                                Email: support@dfu-solutions.com
                                <br></br>

                                You may contact our agent for notice of claimed infringement specified above with complaints regarding allegedly infringing posted material and we will investigate those complaints. If the posted material is believed in good faith by us to violate any applicable law, we will remove or disable access to any such material, and we will notify the posting party that the material has been blocked or removed.
                                <br></br>

                                In notifying us of alleged copyright infringement, the Digital Millennium Copyright Act requires that you include the following information: (i) description of the copyrighted work that is the subject of claimed infringement; (ii) description of the infringing material and information sufficient to permit us to locate the alleged material; (iii) contact information for you, including your address, telephone number and/or e-mail address; (iv) a statement by you that you have a good faith belief that the material in the manner complained of is not authorized by the copyright owner, or its agent, or by the operation of any law; (v) a statement by you, signed under penalty of perjury, that the information in the notification is accurate and that you have the authority to enforce the copyrights that are claimed to be infringed; and (vi) a physical or electronic signature of the copyright owner or a person authorized to act on the copyright owner’s behalf. Failure to include all of the above-listed information may result in the delay of the processing of your complaint.

                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">15. DISCLAIMER OF WARRANTIES</h2>
                            <p className="mb-4 text-gray-200">
                                EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, THE SITE AND ALL CONTENT ARE PROVIDED ON AN “AS IS”, “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE MAKE NO, AND EXPRESSLY DISCLAIM ANY AND ALL, REPRESENTATIONS AND WARRANTIES AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY, AND/OR COMPLETENESS OF ANY INFORMATION ON THIS SITE. WE DO NOT REPRESENT OR WARRANT, AND EXPRESSLY DISCLAIM THAT: (A) THE USE OF THE SITE WILL BE SECURE, TIMELY, UNINTERRUPTED, OR ERROR-FREE, OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA, (B) THE SITE OR OUR PRODUCTS OR SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS IN THE SITE WILL BE CORRECTED, OR (F) THE SITE OR THE SERVER(S) THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ALL CONDITIONS, REPRESENTATIONS, AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">16. DISCLAIMER OF LIABILITIES</h2>
                            <p className="mb-4 text-gray-200">
                                EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, IN NO EVENT SHALL Done-For-You Solutions OR ANY OF ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, INDEPENDENT CONTRACTORS, TELECOMMUNICATIONS PROVIDERS, AND/OR AGENTS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE, OR ANY OTHER DAMAGES, FEES, COSTS, OR CLAIMS ARISING FROM OR RELATED TO THIS AGREEMENT, THE PRIVACY POLICY, THE PRODUCTS OR SERVICES, OR YOUR OR A THIRD PARTY’S USE OR ATTEMPTED USE OF THE SITE OR ANY PRODUCT OR SERVICE, REGARDLESS OF WHETHER Done-For-You Solutions HAS HAD NOTICE OF THE POSSIBILITY OF SUCH DAMAGES, FEES, COSTS, OR CLAIMS. THIS INCLUDES, WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA, LOSS OF GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE SERVICES OR PRODUCTS, OR ANY OTHER INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR OTHER DAMAGES. THIS APPLIES REGARDLESS OF THE MANNER IN WHICH DAMAGES ARE ALLEGEDLY CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), WARRANTY, OR OTHERWISE. IF, NOTWITHSTANDING THE LIMITATIONS OF LIABILITY SET FORTH ABOVE, Done-For-You Solutions IS FOUND LIABLE UNDER ANY THEORY, Done-For-You Solutions’S LIABILITY AND YOUR EXCLUSIVE REMEDY WILL BE LIMITED TO USD $500.00. THIS LIMITATION OF LIABILITY SHALL APPLY FOR ALL CLAIMS, REGARDLESS OF WHETHER Done-For-You Solutions WAS AWARE OF OR ADVISED IN ADVANCE OF THE POSSIBILITY OF DAMAGES OR SUCH CLAIMS. SOME STATES DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">17. DISPUTE RESOLUTION BY MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER
                            </h2>
                            <p className="mb-4 text-gray-200">
                                PLEASE READ THIS ARBITRATION PROVISION CAREFULLY TO UNDERSTAND YOUR RIGHTS. EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY CLAIM THAT YOU MAY HAVE IN THE FUTURE MUST BE RESOLVED THROUGH FINAL AND BINDING CONFIDENTIAL ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE WAIVING THE RIGHT TO A TRIAL BY JURY. THE RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT EXIST. YOU AGREE THAT YOU MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF (LEAD OR OTHERWISE) OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING.
                                <br></br>

                                THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THE TERMS OF THESE CONDITIONS OF USE AS A COURT WOULD.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">General</h2>
                            <p className="mb-4 text-gray-200">

                                Arbitration is a manner of resolving a “Claim” without filing a lawsuit. “Claim” means any dispute between you, Done-For-You Solutions, or any involved third party relating to your account, your use of the Site, your relationship with Done-For-You Solutions, these Terms, or the Privacy Policy. This includes any and all claims that relate in any way to your use or attempted use of the products or services, and any act or omission by Done-For-You Solutions, or any third party related to your use or attempted use of the products or services. You, Done-For-You Solutions, or any involved third party may pursue a Claim. Done-For-You Solutions agrees to final and binding confidential arbitration should it have any Claims against you. Likewise, you agree to final and binding confidential arbitration should you have any Claims against Done-For-You Solutions. By agreeing to arbitrate, you waive the right to go to court and agree instead to submit any Claims to final and binding confidential arbitration. This arbitration provision sets forth the terms and conditions of our agreement to final and binding confidential arbitration and is governed by and enforceable under the Federal Arbitration Act (the “FAA”), 9 U.S.C. §§ 1-16, as amended.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Exceptions</h2>
                            <p className="mb-4 text-gray-200">
                                Notwithstanding the foregoing, and as an exception to final and binding confidential arbitration, you and Done-For-You Solutions both retain the right to pursue, in small claims court, any claim that is within that court’s jurisdiction and proceeds on an individual (non-class) basis, including overdue account matters within the small claims court’s jurisdiction. Done-For-You Solutions will not demand arbitration in connection with any individual claim that you properly file and pursue in a small claims court, so long as the claim is and remains pending in that court. The following claims shall not be subject to final and binding arbitration and must be adjudicated only in the state or federal courts located in Florida: (i) an action by Done-For-You Solutions relating to the infringement or validity of our proprietary rights, including without limitation, trademarks, service marks, trade dress, copyrights, trade secrets, or patents; or (ii) an action by Done-For-You Solutions for temporary, preliminary, or permanent injunctive relief, whether prohibitive or mandatory, or other provisional relief, against you for breach or threatened breach of this Agreement. You expressly agree to refrain from bringing or joining any claims that are excluded from final and binding arbitration pursuant to this subsection “b” in any representative or class-wide capacity, including but not limited to bringing or joining any claims in any class action or any class-wide arbitration. Small claims matters may be filed in any small claims court with personal and subject matter jurisdiction over the parties. For all other matters excluded from final and binding arbitration by this subsection “b,” the parties consent to exclusive jurisdiction and venue in the state and federal courts located in Miami-Dade County, Florida, and forever waive any challenge to said courts’ jurisdiction and venue.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">
                                Required Pre-Dispute Procedures</h2>
                            <p className="mb-4 text-gray-200">
                                We acknowledge and agree that before initiating any Claim against the other, we agree to first contact the other with a written description of the dispute, which shall include all relevant documents and information, and the proposed resolution. You may send the written description of any dispute you have with us by U.S. Mail to Done-For-You Solutions, Attn: 7901 4th St N
                                STE 300 St. Petersburg, FL. 33702. Done-For-You Solutions will contact you by letter at the billing address you provided to us or at the email address you provided to us. You agree to negotiate with Done-For-You Solutions or its designated representative in good faith about your problem or dispute. If for some reason the dispute is not resolved within 60 days after receipt of the written dispute, we agree to the dispute resolution provisions below. Notwithstanding the foregoing or any other term of this arbitration agreement, we will have the right to seek injunctive or other equitable relief in state or federal court located in Miami-Dade County, Florida to enforce these Terms or prevent an infringement of a third party’s rights or our intellectual property rights, as stated in subsection “b” above. You hereby expressly consent to, and forever waive any challenge to, the exclusive personal jurisdiction and venue of said courts in such actions.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Commencing Arbitration</h2>
                            <p className="mb-4 text-gray-200">

                                You and Done-For-You Solutions agree to commence any arbitration proceeding within 1 year after the Claim arises (the 1 year period includes the required pre-dispute procedures set forth above) and that any arbitration proceeding commenced after 1 year shall be forever barred.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Arbitration Location
                            </h2>
                            <p className="mb-4 text-gray-200">
                                If the amount in controversy is $500 or less, then the arbitration may be conducted by telephone or by written submissions. Otherwise, the arbitration shall be conducted in Miami-Dade County, Florida unless Done-For-You Solutions otherwise agrees to arbitrate in another forum requested by you.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Organization, Rules and the Arbitrator</h2>
                            <p className="mb-4 text-gray-200">
                                We each agree that any and all Claims other than those exempted under subsection “b” above shall be submitted to final and binding confidential arbitration before a single arbitrator of the American Arbitration Association (“AAA”). Either party may commence the arbitration process by submitting a written demand for arbitration with the AAA, and providing a copy to the other party, within the time period set forth in subsection “d” above. The arbitrator shall be selected by agreement of the parties or, if the parties cannot agree, chosen in accordance with Rules of the AAA. The arbitration will be conducted in accordance with the provisions of the AAA’s Commercial Dispute Resolutions Procedures, Supplementary Procedures for Consumer-Related Disputes, in effect at the time of submission of the demand for arbitration. The AAA’S Rules are available at www.adr.org or by calling 1-800-778-7879. The arbitrator shall have the exclusive and sole authority to resolve any dispute relating to the interpretation, construction, validity, applicability, or enforceability of these Terms, the Privacy Policy, and this arbitration provision. The arbitrator shall have the exclusive and sole authority to determine whether any dispute is arbitrable. The arbitrator shall have the exclusive and sole authority to determine whether this arbitration agreement can be enforced against a non-signatory to this agreement and whether a non-signatory to this agreement can enforce this provision against you or Done-For-You Solutions.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Fees</h2>
                            <p className="mb-4 text-gray-200">
                                Payment of all filing, administration and arbitrator fees will be governed by the AAA’s Rules. We will reimburse the fees charged by the arbitrator for claims totaling less than $10,000 unless the arbitrator determines the claims are frivolous. Likewise, we will not seek attorneys’ fees and costs in arbitration unless the arbitrator determines the claims are frivolous. In all other respects, the parties shall each pay their own additional fees, costs, and expenses, including, but not limited to, those for any attorneys, experts, documents, and witnesses.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Governing Law and Award</h2>
                            <p className="mb-4 text-gray-200">
                                The arbitrator shall follow the substantive law of the State of Florida without regard to its conflicts of laws principles. Any award rendered shall include a confidential written opinion and shall be final, subject to appeal under the FAA. Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Enforceability</h2>
                            <p className="mb-4 text-gray-200">
                                This provision survives termination of your account or relationship with Done-For-You Solutions, bankruptcy, assignment, or transfer. If the class action waiver is deemed unenforceable (i.e., unenforceability would allow arbitration to proceed as a class or representative action), then this entire arbitration provision shall be rendered null and void and shall not apply. If a portion of this arbitration provision (other than the class action waiver) is deemed unenforceable, the remaining portions of this arbitration provision shall remain in full force and effect.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Miscellaneous</h2>
                            <p className="mb-4 text-gray-200">
                                Failure or any delay in enforcing this arbitration provision in connection with any particular Claim will not constitute a waiver of any rights to require arbitration at a later time or in connection with any other Claims except all Claims must be brought within the 1 year limitation period set forth above. This provision is the entire arbitration agreement between you and Done-For-You Solutions and shall not be modified except in writing by Done-For-You Solutions.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">Amendments</h2>
                            <p className="mb-4 text-gray-200">

                                Done-For-You Solutions reserves the right to amend this arbitration provision at any time. Your continued use of the Site, purchase of a product or service on or through the Site, or use or attempted use of a Done-For-You Solutions product or service, is affirmation of your consent to such changes. Should the changes to this arbitration provision be material, Done-For-You Solutions will provide you notice and an opportunity to opt-out. Your continued use of the Site, purchase of a product or service on or through the Site, or use or attempted use of a Done-For-You Solutions product or service, is affirmation of your consent to such material changes.
                                <br></br>
                                YOU UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL, AND CONFIDENTIAL ARBITRATION. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN THIRTY (30) DAYS FROM THE DATE THAT YOU PURCHASE, USE, OR ATTEMPT TO USE A PRODUCT OR SERVICE PURCHASED ON OR THROUGH THE SITE (WHICHEVER COMES FIRST) BY WRITING TO US AT Done-For-You Solutions, ATTN: LEGAL DEPARTMENT, PO BOX 554, ASHLAND, OR 97520. FOR YOUR OPT-OUT TO BE EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN NOTICE IDENTIFYING ANY PRODUCT OR SERVICE YOU PURCHASED, USED OR ATTEMPTED TO USE WITHIN THE 30 DAYS AND THE DATE YOU FIRST PURCHASED, USED OR ATTEMPTED TO USE THE PRODUCT OR SERVICE. IF MORE THAN THIRTY (30) DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE TO OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING ARBITRATION AS SET FORTH IN THIS AGREEMENT. SHOULD EITHER PARTY FILE AN ACTION CONTRARY TO THIS PROVISION, THE OTHER PARTY MAY RECOVER ATTORNEY’S FEES AND COSTS UP TO $1,000.00.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">18. Indemnification
                            </h2>
                            <p className="mb-4 text-gray-200">
                                To the fullest extent permitted by law, you agree to indemnify, defend, and hold harmless Done-For-You Solutions, its affiliates (including without limitation all affiliated professional entities), subsidiaries, and their directors, officers, employees, contractors, licensors, suppliers, representatives, proprietors, partners, shareholders, servants, principals, agents, predecessors, successors, assigns, accountants, and attorneys from and against any claims, actions, losses, liabilities, damages, expenses, demands, and costs of any kind, including, but not limited to, reasonable attorneys’ fees, arising out of, resulting from, or in any way connected with or related to (1) your breach of these Terms, the documents they incorporate by reference, or the Agreement; (2) your breach of any representations or warranties in this Agreement; or (3) your violation of any law or the rights of a third-party.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">19. Third-Party Websites and Links</h2>
                            <p className="mb-4 text-gray-200">
                                Our Site may include materials from third-parties or links to third-party websites. Done-For-You Solutions is not liable for any third-party materials or websites. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">20. Testimonials, Reviews, and Other Submissions</h2>
                            <p className="mb-4 text-gray-200">

                                Done-For-You Solutions is pleased to hear from users and customers and welcomes your comments regarding our services and products. You agree that any testimonial or product review that you provide Done-For-You Solutions is true and accurate in all respects and does not infringe or interfere with the intellectual property rights of any third party. You agree that Done-For-You Solutions may use testimonials and/or product/service reviews, in whole or in part, together with the name and city/state/country of the person submitting it. Testimonials may be used for any form of advertising relating to Done-For-You Solutions’s products or services, in printed and online media, as Done-For-You Solutions determines in its absolute discretion. Testimonials represent the unique experience of the customers submitting the testimonial, and do not necessarily reflect the experience that you may have using our products or services.

                                Anything that you submit or post to the Site and/or provide us, including without limitation, photographs, testimonials, ideas, know-how, techniques, questions, reviews, comments, and suggestions (collectively, “Submissions”) is and will be treated as non-confidential and nonproprietary, and we shall have the royalty-free, worldwide, perpetual, irrevocable, and transferable right to use, copy, distribute, display, publish, perform, sell, lease, transmit, adapt, and create derivative works from such Submissions by any means and in any form, and to translate, modify, reverse-engineer, disassemble, or decompile such Submissions. You represent and warrant that you are the owner or have sufficient rights to share the Submissions with us. All Submissions shall automatically become our sole and exclusive property and shall not be returned to you.

                                Additionally, Done-For-You Solutions reserves the right to correct grammatical and typing errors, to shorten testimonials prior to publication or use, and to review all testimonials prior to publication or use. Done-For-You Solutions shall be under no obligation to use any, or any part of, any testimonial or product/service review submitted. If you submit a testimonial, you are confirming that you have read, understood and agree to these Terms. If you disagree with any part of these Terms, do not submit a testimonial.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">21. Electronic Communications </h2>
                            <p className="mb-4 text-gray-200">

                                You agree that we may communicate electronically with you and that such communications, as well as notices, disclosures, agreements, and other communications that we provide to you electronically, are equivalent to communications in writing and shall have the same force and effect as if they were in writing and signed by the party sending the communication.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">22. Assignment </h2>
                            <p className="mb-4 text-gray-200">
                                You may not assign any of your rights under these Terms, and any such attempt will be null and void. Done-For-You Solutions and its affiliates may, in their individual discretion, transfer, without further consent or notification, all contractual rights and obligations pursuant to these Terms if some or all of Done-For-You Solutions’s business is transferred to another entity by way of merger, sale of its assets, or otherwise.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">23. No Waiver</h2>
                            <p className="mb-4 text-gray-200">
                                No waiver by Done-For-You Solutions of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by Done-For-You Solutions to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">24. No Agency Relationship </h2>
                            <p className="mb-4 text-gray-200">
                                No joint venture, partnership, employment, or agency relationship exists between you and Done-For-You Solutions as a result of your receipt of any Done-For-You Solutions product, your use of any Done-For-You Solutions service, or use of the Site.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">25. Severability
                            </h2>
                            <p className="mb-4 text-gray-200">
                                In the event that any provision of these Terms is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms. Such determination shall not affect the validity and enforceability of any other remaining provisions.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">26. Termination</h2>
                            <p className="mb-4 text-gray-200">
                                In the event that we terminate this Agreement, Sections 1-28, as well as any representations, warranties, and other obligations made or taken by you, shall survive the termination of this Agreement.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">27. Entire Agreement </h2>
                            <p className="mb-4 text-gray-200">
                                These Terms, the Agreement, and any policies or operating rules posted by us on the Site or in respect to the Site constitutes the entire agreement and understanding between you and Done-For-You Solutions, and supersedes and replaces any prior or contemporaneous agreements. Any ambiguities in the interpretation of these Terms or the Agreement shall not be construed against the drafting party.
                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100">28. Questions or Additional Information</h2>
                            <p className="mb-4 text-gray-200">
                                If you have any questions regarding your account, any product or service, or these Terms, please contact our customer support team by phone, mail, and email at the following:
                                <br></br> <br></br>Done-For-You Solutions, LLC
                                <br></br> <br></br>7901 4th St N
                                <br></br> <br></br>STE 300
                                <br></br> <br></br>St. Petersburg, FL. 33702
                                <br></br> <br></br>Phone: +1(817)-889-2359
                                <br></br> <br></br>Email: support@dfu-solutions.com

                            </p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100"></h2>
                            <p className="mb-4 text-gray-200"></p>
                        </section>
                        <section>
                            <h2 className="mb-2 text-lg font-semibold text-gray-100"></h2>
                            <p className="mb-4 text-gray-200"></p>
                        </section>

                        {/* Additional sections would go here */}
                    </div>
                </div>
            </div>
        </div>
    );
};


