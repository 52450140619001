'use client'
import { Environment } from '@react-three/drei'
import { Suspense, useState } from 'react'

import React from "react";
import { View } from '../components/View'
import { Forex } from '../components/Examples'
import Footer from '../footer'

export default function DisclaimerPage() {
    const [mouse, setMouse] = useState({
        x: (typeof window == "undefined") ? -1 : window.innerWidth / 2,
        y: (typeof window == "undefined") ? 0 : window.innerHeight / 2,
        deltaX: 0,
    })
    const onMove = (e) => {
        setMouse({ x: e.clientX, y: e.clientY, deltaX: e.movementX });
    }

    return (
        <div className='font-sans no-scrollbar  flex h-screen min-h-screen flex-col justify-between overflow-x-hidden overflow-y-scroll'

            onTouchMove={(e) => {
                setMouse({ x: e.touches[0].clientX, y: e.touches[0].clientY, deltaX: e.touches[0].clientX < window.innerWidth / 2 ? -1 : 1 })
            }}
            onMouseMove={onMove}

        >
            <Disclaimer />
            <View orbit={false} className='fixed left-0 top-0 z-10 m-0 h-screen w-screen p-0  '>
                <Suspense fallback={null}>
                    <Environment preset='apartment' ></Environment>
                    <Forex
                        resetMouse={() => { setMouse({ x: window.innerWidth / 2, y: window.innerHeight / 2, deltaX: 0 }) }}
                        mouse={mouse} scale={2} position={[0, -1.6, 0]} rotation={[0.0, -0.3, 0]} />
                </Suspense>
            </View>
            <div className="hero absolute left-0 top-0 z-0 h-full w-full  bg-gradient-to-r from-cyan-950 to-blue-950">

            </div>
            <div className="relative z-50">
                <Footer policy={true}></Footer>

            </div>
        </div>
    )
}


const Disclaimer = () => {
    return (
        <div className="relative z-20 mx-4 flex  items-center justify-center py-4 drop-shadow-lg lg:pt-10">
            <div className="w-full  max-w-5xl rounded border-8 border-blue-950 bg-black/80    text-white   shadow">
                <div className="flex flex-col items-center  bg-blue-950">
                    <div className="flex   flex-col items-center justify-center border-b border-dotted border-gray-200 py-4 text-center">
                        <h1 className=" pb-2 text-2xl font-bold">DISCLAIMER</h1>
                        <p className=" max-w-xl    text-sm ">Last Updated On: February 12, 2024</p>
                    </div>
                    <div className="h-4"></div>

                </div>
                <div className="p-8 text-black">
                    <div className="space-y-6">
                        {/* Section 1 */}
                        <section>

                            <p className="mb-4 text-gray-200">Done-For-You Solutions is a financial technology and education company. We do not offer a &quot;get rich quick&quot; program or a guaranteed money-making system. We provide education and tools to help individuals make informed investment decisions. Our success in training is not guaranteed, and we do not claim that our training will result in earnings. All material, except third-party content, on our website is proprietary and copyright-protected. Reproduction or distribution without permission is prohibited. See our Full Disclosure for details.
                                <br></br>
                                <br></br>
                                Risk Warning:
                                <br></br>
                                <br></br>

                                Required Disclaimer - Trading with our AI-driven algorithms involves significant risk. It may not be suitable for all investors due to the high level of risk and leverage. Before investing, carefully consider your objectives, experience, and risk appetite. You could lose some or all of your initial investment; do not invest money you can&quot;t afford to lose. Seek advice from an independent financial advisor if you have doubts.
                                <br></br>
                                <br></br>

                                Our products and services, as referenced on our website, are not offered by registered intermediaries. We do not represent that profits or losses similar to those discussed are likely or achievable. Past performance is not indicative of future results.
                                <br></br>
                                <br></br>

                                This information is educational and not individual investment advice. Act only on advice from your investment professional tailored to your needs. Failure to seek detailed professional advice could lead to decisions contrary to your interests and capital loss.
                                <br></br>
                                <br></br>

                                *CFTC RULE 4.41(b)(1)/NFA RULE 2-29 - Simulated or hypothetical performance results have limitations. They do not represent actual trading and may not accurately reflect the impact of market factors. Hindsight and the absence of financial risk in hypothetical trading can lead to different results from actual trading. No representation is made that any account will achieve profits or losses similar to those shown.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
};


