'use client'

import { Box, PerspectiveCamera, RoundedBox, useGLTF, useIntersect } from '@react-three/drei'
import { dispose, useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Line, useCursor, MeshDistortMaterial } from '@react-three/drei'
import { motion } from 'framer-motion-3d'
import { UnrealBloomPass } from 'three-stdlib'
import { Suspense } from 'react'
import { Stats } from '@react-three/drei'
import { Bloom, EffectComposer } from '@react-three/postprocessing'


const candleWidth = 1;
const candleSpacing = 0.25;
const defaultInterval = window.location.hash == '#2' ? 1000 : 750



const Candle = memo(function Candle({ open, high, low, liveClose, time, x, disp }) {

  const bodyHeight = Math.abs(liveClose - open);

  const bodyYPosition = (liveClose + open) / 2;
  const color = liveClose > open ? 'white' : '#3786f3';

  const wickHeight = Math.abs(high - low);
  const wickYPosition = (high + low) / 2;

  const { camera } = useThree()

  return (
    <group position={[x, 0, 0]} >
      <RoundedBox
        bevelSegments={0.5}
        radius={0.1}
        args={[candleWidth, bodyHeight, 1]}
        position={[0, bodyYPosition, 0]}
      >
        <meshStandardMaterial emissive={color} envMapIntensity={0.2} transparent opacity={0.5} metalness={1} roughness={0.2} color={color} />
      </RoundedBox>
      <Box
        rotateZ={(Math.PI / 2)}
        args={[0.1, wickHeight, 0.1, 1, 1, 1]}
        position={[0, wickYPosition, 0]}
        material-color={color}
      >
        <meshStandardMaterial metalness={0.8} emissive={color} roughness={0.2} color={color} />
      </Box>
    </group>
  );
});


class CandleModel {
  constructor(open, high, low, close, time, originalIndex, x) {
    this.open = open;
    this.high = high;
    this.low = low;
    this.close = close;
    this.time = time;
    this.originalIndex = originalIndex;
    this.x = x;
  }
}



export const Forex = memo(function Forex(props) {
  const [intervalMs, setIntervalMs] = useState(500);

  const { camera, gl, viewport } = useThree()
  const [candles, setCandles] = useState([]);
  const [lastCandleTime, setLastCandleTime] = useState(0);

  const [candleTime, setCandleTime] = useState(0);

  const [nextCandleX, setNextCandleX] = useState(0);
  const [cameraX, setCameraX] = useState(0);


  useFrame((_, delta) => {
    setCandleTime((time) => time + delta)
    if (candleTime - lastCandleTime > intervalMs / 1000) {
      setLastCandleTime(candleTime);
      setNextCandleX(nextCandleX + candleWidth + candleSpacing);

      addCandle();
    }
  });

  useEffect(() => {
    setLastCandleTime(candleTime);
    setNextCandleX(nextCandleX + candleWidth + candleSpacing);
    addCandle();
  }, [])

  const [lastClose, setLastClose] = useState(Math.random() * 1);

  const mouse = props.mouse
  useEffect(() => {
    const mouseXRelative = (mouse.x / window.innerWidth - 0.5) * viewport.width + cameraX;

    const lastCandleX = candles.length > 0 ? candles[candles.length - 1].x : 0;
    const distanceToMouse = mouseXRelative - lastCandleX;
    if (lastCandleX < (mouseXRelative + candleWidth) && mouse.x != -1) {
      if (distanceToMouse < 1) return
      const maxDistance = viewport.width / 2; // Maximum distance for interpolation
      const minInterval = 10; // Minimum possible interval
      const interpolationFactor = Math.max(0, Math.min(1, (distanceToMouse * (viewport.width / 7)) / maxDistance));
      const increasedInterval = minInterval + (defaultInterval - minInterval) * (1 - interpolationFactor);
      setIntervalMs(increasedInterval * 0.8);
    } else if (mouse.x != -1) {
      setIntervalMs(defaultInterval);
      setCameraX(cameraX => cameraX + candleWidth + candleSpacing);
    } else {
      setIntervalMs(defaultInterval);
    }
  }, [mouse, candles, intervalMs, cameraX, viewport.width]);


  const addCandle = () => {
    const open = candles[candles.length - 1]?.close || lastClose;

    const close = open;

    const high = open;
    const low = open;
    const time = lastCandleTime + 1;

    const x = nextCandleX;

    const newCandle = new CandleModel(open, high, low, close, time, candles.length, x);

    setCandles([...candles, newCandle]);
    setCandles(candles => {
      const filtered = candles.filter((candle, index) => (cameraX - candle.x < viewport.width * (mouse.x == -1 ? 2 : 2)) || candles.length < (mouse.x == -1 ? viewport.width * 3 : viewport.width))
      return filtered.length < 1 ? candles : filtered
    })

    setLastClose(close);
  };

  useFrame(() => {
    if (candles.length === 0) return;

    const lastCandleIndex = candles.length - 1;
    if (candles[lastCandleIndex] === undefined) return;
    const lastCandleX = candles.length > 0 ? candles[candles.length - 1].x : 0
    // const liveClose = Math.sin(lastCandleX / 5) * 6 + 6;
    const liveClose = calculateLiveClose(candles[lastCandleIndex], mouse);
    if (lastCandleIndex >= 0 && candles.length > 0) {
      setCandles(candles => {
        let newCandles = [...candles];
        if (newCandles[newCandles.length - 1] === undefined) return;
        newCandles[newCandles.length - 1].close = liveClose;
        newCandles[newCandles.length - 1].high = Math.max(newCandles[newCandles.length - 1].high, liveClose);
        newCandles[newCandles.length - 1].low = Math.min(newCandles[newCandles.length - 1].low, liveClose);
        return newCandles;
      });

    }
  }, [candles]);


  const calculateLiveClose = (candle, mouse) => {
    const clientY = mouse.y;
    const y = (- (clientY / window.innerHeight) + 0.5);
    return Math.max(-10, y * viewport.height + camera.position.y);
  };

  return (
    <>
      <motion.group animate={{
        x: -cameraX
      }}

        transition={{
          ease: mouse.deltaX < -0.05 ? 'easeOut' : 'linear',
          duration: mouse.deltaX < -0.05 ? 5 / viewport.width : (intervalMs + 10) / 1000,
        }}
      >
        <Suspense fallback={null}>
          <EffectComposer>
            <Bloom mipmapBlur levels={7} luminanceThreshold={0.3} intensity={0.5}
            />
          </EffectComposer>
        </Suspense>
        {candles.map((candle, index) => (
          <Candle
            key={index}
            open={candle.open}
            high={candle.high}
            low={candle.low}
            liveClose={candle.close}
            time={candleTime}
            x={candle.x}

          />
        ))}

      </motion.group>
      <PerspectiveCamera
        makeDefault
        fov={30}
        position={[0, 5, 50]}
      />
    </>
  );
})
