import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import logo from './img/logo.png';


const Footer = ({ ...props }) => {
    const [gradientPosition, setGradientPosition] = useState('50% 50%');

    const handleMouseMove = (event) => {
        // Get the bounding rectangle of the target
        const rect = event.target.getBoundingClientRect();

        // Calculate the mouse position relative to the target
        const x = ((event.clientX - rect.left) / rect.width) * 100;
        const y = ((event.clientY - rect.top) / rect.height) * 100;

        setGradientPosition(`${x}% ${y}%`);
    };

    return (
        <div className="bg-black  p-6 text-xs text-gray-200">
            <div className="mx-auto flex max-w-7xl flex-col-reverse items-center justify-center">
                {/* Text section */}
                {!props.policy && <div className="col-span-3 cursor-default space-y-4">
                    <p>DISCLAIMER: The mentioned figures on this website, or provided by us, should not be considered as a guarantee or promise of specific results. Individual experiences vary, and you may not achieve similar outcomes. The owners of Done-For-You Solutions have achieved their success over time, influenced by the right conditions during the relevant period. Please be aware that results are subject to variation, and any potential results are dependent on many factors during relevant periods. </p>

                    <p>U.S. Government Required Disclaimer – Trading foreign exchange on margin carries a high level of risk, and may not be suitable for all investors. The high degree of leverage can work against you as well as for you. Before deciding to invest in foreign exchange you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with foreign exchange trading, and seek advice from an independent financial advisor if you have any doubts.</p>

                    <p>The purchase, sale or advice regarding a currency can only be performed by a licensed Broker/Dealer. Neither us, nor our affiliates or associates involved in the production and maintenance of these products or this site, is a registered Broker/Dealer or Investment Advisor in any State or Federally-sanctioned jurisdiction. All purchasers of products referenced at this site are encouraged to consult with a licensed representative of their choice regarding any particular trade or trading strategy. No representation is being made that any account will or is likely to achieve profits or losses similar to those discussed on this website. The past performance of any trading system or methodology is not necessarily indicative of future results.</p>

                    <p>Clearly understand this: Information contained in this product are not an invitation to trade any specific investments. Trading requires risking money in pursuit of future gain. That is your decision. Do not risk any money you cannot afford to lose. This document does not take into account your own individual financial and personal circumstances. It is intended for educational purposes only and NOT as individual investment advice. Do not act on this without advice from your investment professional, who will verify what is suitable for your particular needs & circumstances. Failure to seek detailed professional personally tailored advice prior to acting could lead to you acting contrary to your own best interests & could lead to losses of capital.</p>

                    <p>*CTFC RULE 4.41 – HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE CERTAIN LIMITATIONS. UNLIKE AN ACTUAL PERFORMANCE RECORD, SIMULATED RESULTS DO NOT REPRESENT ACTUAL TRADING. ALSO, SINCE THE TRADES HAVE NOT BEEN EXECUTED, THE RESULTS MAY HAVE UNDER-OR-OVER COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN MARKET FACTORS, SUCH AS LACK OF LIQUIDITY. SIMULATED TRADING PROGRAMS IN GENERAL ARE ALSO SUBJECT TO THE FACT THAT THEY ARE DESIGNED WITH THE BENEFIT OF HINDSIGHT. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFIT OR LOSSES SIMILAR TO THOSE SHOWN.</p>
                </div>
                }
                {/* Links and Logo section */}
                <div className="mb-6 flex flex-col items-center md:items-center">
                    {/* Placeholder for the logo with Framer Motion */}
                    <motion.div
                        className="mb-6 w-32 rounded-2xl text-center leading-8 text-white"

                    >
                        <a href="/">
                            <img src={logo} alt="Done4You Solutions Logo" />

                        </a>
                    </motion.div>
                    <div className="flex flex-row font-bold lg:text-xl">
                        <a href={'/privacy'}>
                            <p className="px-4 text-center md:text-right">Privacy Policy</p>

                        </a>
                        <a href={'/terms'}>

                            <p className="px-4 text-center md:text-right">Terms Of Use</p>
                        </a>

                        <a href={'/disclaimer'}>

                            <p className="px-4 text-center md:text-right">Disclaimer</p>
                        </a>

                    </div>

                </div>

            </div>
            <div className="mt-4 text-center">
                <p>The Site Is Not A Part Of Facebook Website Or Facebook, Inc.</p>
                <p>This Site Is Not Endorsed By Facebook In Any Way. FACEBOOK Is A Trademark Of FACEBOOK, Inc.</p>
                <p>This site is not a part of or endorsed by Facebook, Inc. in any way.</p>
                <p>Copyright 2024 - Done-For-You Solutions LLC - All Rights Reserved.</p>
            </div>
        </div>
    );
};

export default Footer;
