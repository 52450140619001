'use client'

import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
export function PipedriveForm({ ...props }) {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const formRef = useRef(null);
    const overlayRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');

        script.onload = () => {
            setScriptLoaded(true);
        };
        script.src = "https://webforms.pipedrive.com/f/loader";

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Mouse move event to capture coordinates over the iframe


    const handleMouseMove = (event) => {
        setFormInteractive(false)

    };

    const handleScroll = (event) => {
        setFormInteractive(false)
    }

    const container = (typeof document == "undefined") ? null : document.getElementById('container');

    useEffect(() => {
        // Attach the mousemove listener to the overlay
        const overlay = overlayRef.current;
        if (container) {

            container.addEventListener('pointermove', handleMouseMove);
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            // Clean up the event listener
            if (container) {
                container.removeEventListener('pointermove', handleMouseMove);
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [container]); // Depend on overlayRef to ensure the effect runs correctly

    const [formInteractive, setFormInteractive] = useState(true);



    return (
        <div className="flex flex-col" suppressHydrationWarning={true}>
            <div className="pipedriveWebForms relative drop-shadow-lg" ref={formRef}
                style={{
                    pointerEvents: formInteractive || props.popup ? 'auto' : 'none',
                }}

                data-pd-webforms="https://webforms.pipedrive.com/f/6WdPYyROEfETDB16JJCbJSaJ1hZAV3c8rqsHefwMO0jwCMNof80NI87S9kbW4jTRjd">
                <motion.div

                    ref={overlayRef}
                    onTap={(e) => {
                        setFormInteractive(true);
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        pointerEvents: formInteractive || props.popup ? 'none' : 'auto',
                        bottom: 0,
                        zIndex: 100,
                        cursor: 'pointer', // Change cursor style to indicate clickable area
                    }}
                ></motion.div>
            </div>
            <p className="mt-0 pl-0 text-xs lg:pl-5">By submitting, you agree to the Privacy Policy, Terms of Use and Disclaimer.</p>
        </div>
    );
}
