'use client'
import { Environment } from '@react-three/drei'
import { Suspense, useState } from 'react'
import { Forex } from '../components/Examples'
import { View } from '../components/View'
import Footer from '../footer'



export default function Privacy() {
  const [mouse, setMouse] = useState({
    x: (typeof window == "undefined") ? -1 : window.innerWidth / 2,
    y: (typeof window == "undefined") ? 0 : window.innerHeight / 2,
    deltaX: 0,
  })
  const onMove = (e) => {
    setMouse({ x: e.clientX, y: e.clientY, deltaX: e.movementX });
  }

  return (
    <div className='font-sans no-scrollbar  flex h-screen  min-h-screen flex-col justify-between overflow-x-hidden'

      onTouchMove={(e) => {
        setMouse({ x: e.touches[0].clientX, y: e.touches[0].clientY, deltaX: e.touches[0].clientX < window.innerWidth / 2 ? -1 : 1 })
      }}
      onMouseMove={onMove}

    >
      <PrivacyPolicy />
      <View orbit={false} className='fixed left-0 top-0 z-10 m-0 h-screen w-screen p-0  '>
        <Suspense fallback={null}>
          <Environment preset='apartment' ></Environment>
          <Forex
            resetMouse={() => { setMouse({ x: window.innerWidth / 2, y: window.innerHeight / 2, deltaX: 0 }) }}
          mouse={mouse} scale={2} position={[0, -1.6, 0]} rotation={[0.0, -0.3, 0]} />
        </Suspense>
      </View>
      <div className="hero fixed left-0 top-0 z-0 h-full w-full  bg-gradient-to-r from-cyan-950 to-blue-950">

      </div>
      <div className="relative z-50">
        <Footer policy={true}></Footer>

      </div>
    </div>
  )
}


const PrivacyPolicy = () => {
  return (
    <div className="relative z-20 mx-4 flex  items-center justify-center py-4 drop-shadow-lg lg:pt-10">

      <div className="w-full  max-w-5xl rounded border-8 border-blue-950 bg-black/80  text-white  shadow   ">
        <div className="flex flex-col items-center  bg-blue-950">
          <div className="flex   flex-col items-center justify-center border-b border-dotted border-gray-200 py-4 text-center">
            <h1 className=" pb-2 text-2xl font-bold">Privacy Policy</h1>
            <p className=" max-w-xl    text-sm ">Last Updated On: February 12, 2024</p>
          </div>
          <div className="h-4"></div>

        </div>
        <div className="p-8 text-white">
          <div className="space-y-6">
            {/* Section 1 */}
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 1</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">Overview</h2>
              <p className="mb-4 text-gray-300">‍At Done-For-You Solutions LLC., we take privacy issues seriously. By ensuring that you are aware of and understand the Done-For-You Solutions LLC. Privacy Policy, we can provide you with better service. Please take a moment to read the following policy to learn how we handle your personal information.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 2</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">Why do we collect personal information?</h2>
              <p className="mb-4 text-gray-300">‍Done-For-You Solutions LLC. collects personal information to improve the manner by which we operate, offer our products and services, communicate with you about those products and services, and provide effective, timely customer support when needed. We will never rent or sell any of the personal information we collect from you to third parties, and we do not share your personal information except as set forth in this Privacy Policy.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 3</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">‍What kind of personal information do we collect?</h2>
              <p className="mb-4 text-gray-300">‍Done-For-You Solutions LLC. collects personal information in a variety of ways when you interact with Done-For-You Solutions LLC., including when you register or create an account with Done-For-You Solutions LLC.; when you place an order at Done-For-You Solutions LLC.; when you use Done-For-You Solutions LLC. products or services; when you visit Done-For-You Solutions LLC. pages or pages of certain Done-For-You Solutions LLC. partners; when you enter promotions or sweepstakes; when you subscribe to a newsletter or desire to be added to our mailing lists for other products or services; when you correspond or otherwise interact with us; and when you provide feedback in any of our online surveys.
                <br></br>
                <br></br>
                Information we collect may include: your name, e-mail address, phone number, address, product preference information, billing information, demographic information provided by you (such as language, gender and age, and, if applicable, content preferences and personalization information) and personal interest information. On occasion, we may ask for additional information to enable us to provide with access to and use of certain information, materials and services. Done-For-You Solutions LLC. may combine information about you that we have the information we obtain from business partners or other companies.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 4</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">‍What are cookies and how do we use them?
              </h2>
              <p className="mb-4 text-gray-300">‍Cookies are small data files that write to your hard drive for record keeping purposes when you visit a website. Cookies allow Done-For-You Solutions LLC. to measure traffic activity as well as to improve your user experience, for example by remembering your passwords and viewing preferences. Like other major websites, Done-For-You Solutions LLC. uses cookies to provide you with a tailored experience when visiting Done-For-You Solutions LLC. and using our major products.
                <br></br>
                <br></br>

                We treat any personal information that may be contained in cookies with the same level of confidentiality as other information you provide to us. If you wish to disable cookie functionality, you may do so by changing the security settings on your browser. However, portions of Done-For-You Solutions LLC. may not function or function as efficiently, without them.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 5</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">What information is collected from other sources?</h2>
              <p className="mb-4 text-gray-300">‍Social Media Widgets: The Websites include social media features, such as the Facebook Like button, and widgets, such as the Share This button or interactive mini-programs that run on our Websites. These features may collect your Internet protocol address, which page you are visiting on the Websites, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on the Websites. Your interactions with these features are governed by the privacy statement of the companies that provide them.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 6</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">How do we protect your personal information?</h2>
              <p className="mb-4 text-gray-300">‍Information From Third Party Services: ‍We may also obtain other information, including personal information, from third parties and combine that with information we collect through our Websites. For example, we may have access to certain information from a third-party social media or authentication service if you log into our Services through such a service or otherwise provide us with access to information from the service. Any access that we may have to such information from a third-party social media or authentication service is in accordance with the authorization procedures determined by that service.
                <br></br>
                <br></br>

                If you authorize us to connect with a third-party service, we will access and store your name, email address(es), current city, profile picture URL, and other personal information that the third-party service makes available to us, and use and disclose it in accordance with this Policy. You should check your privacy settings on these third-party services to understand and change the information sent to us through these services. For example, you can log in to the Services using single sign-in services such as Facebook Connect or an Open ID provider.
                <br></br>
                <br></br>

                These single sign-on services will authenticate your identity, provide you with the option to share certain personal information (such as your name and email address) with us, and pre-populate our sign-up form. Services like Facebook Connect give you the option to post information about your activities in the Services to your profile page to share with others within your network.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 7</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">‍How do we use your personal information?</h2>
              <p className="mb-4 text-gray-300">‍Done-For-You Solutions LLC. safeguards the security of the data you send us with physical, electronic, and managerial procedures. We urge you to take every precaution to protect your personal data when you are on the Internet. To make purchases from Done-For-You Solutions LLC. web site, you must use an SSL-enabled browser. Done-For-You Solutions LLC. uses industry-standard Secure Sockets Layer (SSL) encryption on web pages used to complete a purchase. This protects the confidentiality of your personal and credit card information while it is transmitted over the Internet.
                <br></br>
                <br></br>

                While Done-For-You Solutions LLC. takes precautions to ensure the security of your data, no one can completely guarantee the safety of data transmitted over the internet. It is important that you take steps to ensure the safety and security of your personal information, for example by not sharing passwords or other registration data.
              </p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 8</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">‍How and when we disclose your information we collect.</h2>
              <p className="mb-4 text-gray-300">‍Done-For-You Solutions LLC. uses your personal information in the following ways:Delivery and billing for our products and services, and to communicate with you concerning your account and customer service issues. This may include verifying authorization of credit card usage before any purchase of content or services.Improving our products and services by providing personalized content recommendations, language and location customization, and/or personalized help and instructions.To ask you to participate in surveys about products, services, news and events. Offering you product upgrades, content, services and other training courses for you to purchase.
                <br></br>
                <br></br>

                Providing provide you with content, services, instructions, and a more personalized experience based on personal information, such as your language preferences, zip/postal code, country locations, and any content or other preferences you provide.Providing you with news, free newsletters, updates, targeted advertising, promotions, and special offers. To better understand how our products are used, including Web traffic patterns and what types of content are most popular with users of our products and services.Providing anonymous reporting for internal and external partners.Facilitating your participation in our online forums and helping to manage improper behavior by individuals using the forums.
              </p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 9</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">What are the Legal reasons we collect and disclose certain information?</h2>
              <p className="mb-4 text-gray-300">Done-For-You Solutions LLC. may share your personal information with trusted vendors and partners who work with Done-For-You Solutions LLC.. These partners are provided with your personal information to help Done-For-You Solutions LLC. communicate with you about offers from us and from our marketing partners. They are contractually required to keep this personal information secure and to only use it for the purposes for which it is provided. Done-For-You Solutions LLC. may send personal information about you to other companies or people when we need to share your information to provide the product or service you have requested or to provide a provide a product or service to you. However, these companies are only permitted to use the personal information for these purposes.
                <br></br>
                <br></br>

                Done-For-You Solutions LLC. may also disclose your personal information if required to do so by law or in the good faith belief that such action is necessary to: (a) comply with law or legal process or if requested by duly authorized public authorities; (b) comply with the order of a competent judicial authority in any jurisdiction; (c) comply with legal process served on Done-For-You Solutions LLC.; (d) protect and defend the rights or property of Done-For-You Solutions LLC., or our affiliated companies, their employees and agents; (e) enforce our terms of use; (f) protect against fraud or other illegal activity or for risk management purposes; (g) permit us to pursue available remedies or limit the damages that we may sustain; (g) act in urgent circumstances to protect the personal safety of users of Done-For-You Solutions LLC. products or the public at large.If Done-For-You Solutions LLC. should ever file for bankruptcy, or become insolvent, or be acquired by a third party, or merge with a third party, or sell all or part of its assets, or transfer substantially all or part of its relevant assets to a third party, then Done-For-You Solutions LLC. is entitled to share the personal information and all other information you have provided through the products and service to potential and subsequent business and merger partners.
                <br></br>
                <br></br>

                Done-For-You Solutions LLC. may share “cookie” and other anonymous data about traffic patterns or usage collected on Done-For-You Solutions LLC. with affiliated companies, its business partners or with third party advertisers or ad networks. Any data shared is anonymous and does not identify any user.
                <br></br>
                <br></br>

                Done-For-You Solutions LLC. may also include third party advertising. These third parties’ websites and advertisers, or the Internet advertising companies that work on their behalf, may use technology such as web beacons and cookies to send (or “serve”) the advertisements that appear on our Site directly to your browser. They automatically receive your IP address when this happens. They may also use cookies, JavaScript, electronic images and other technologies to deliver advertisements, collect information about the effectiveness of their advertisements, and to collect anonymous information about your visits to Done-For-You Solutions LLC., such as the number of times you have viewed an ad (but not your name, address, or other personal information). If you would like more information about this practice, as well as your choices about not having this information used for online behavioral advertising by these ad networks, click here: http://networkadvertising.org/consumer/opt_out.asp
                <br></br>
                <br></br>

                Done-For-You Solutions LLC. also contains links to other Websites and products. Done-For-You Solutions LLC. is not responsible for the privacy practices or the content of such Websites or products. Please visit the privacy policies of these third party sites in order to understand their privacy policies and data collection practices.What rights do you have regarding your data?You always have the right to request information about your stored data, its origin, its recipients, and the purpose of its collection at no charge. You also have the right to request that it be corrected, blocked, or deleted. You can contact us at any time using the address given in the legal notice if you have further questions about the issue of privacy and data protection.You may also, of course, file a complaint with the competent regulatory authorities.Many data processing operations are only possible with your express consent. You may revoke your consent at any time with future effect.
                <br></br>
                <br></br>

                An informal email making this request is sufficient.The data processed before we receive your request may still be legally processed.If you no longer wish to receive communications from Done-For-You Solutions LLC., you can opt-out from receiving such messages in the future by following the instructions in the message or contacting us at support@dfu-solutions.com. With respect to the Site’s use of cookies and other similar technologies, by adjusting settings on your browser, you may elect not to allow cookies to be places, and thus opt-out of certain features of the Site and the Site’s automatic collection of certain data about you.
                <br></br>
                <br></br>

                You may also adjust your advertising preferences on various social media websites by adjusting your settings on the platform.If you are accessing the Site from the European Economic Area, which includes the European Union, Iceland, Liechtenstein, and Norway, or you are a citizen of a member state of the European Economic Area, you have the following rights under the EU General Data Protection Regulation (GDPR).Right to be informed about the collection and use of your personal dataRight to access your personal dataRight to have any inaccurate personal data correctedRight to have your personal data erasedRight to restrict use of your personal dataRight to personal data portabilityRight to object to the processing of your personal data.</p>
            </section>
            <section>

              <h2 className="mb-2 text-lg font-semibold text-gray-100">SECTION 10</h2>
              <h2 className="mb-2 text-lg font-semibold text-gray-100">Community Forums:</h2>
              <p className="mb-4 text-gray-300">
                Legal basis for processing (EEA visitors only):If you are a visitor from the European Economic Area, our legal basis for collecting and using the personal information described above will depend on the personal information concerned and the specific context in which we collect it.However, we will normally collect personal information from you only where we need the personal information to perform a contract with you (e.g. to provide you with our Services), where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms, or where we have your consent. In some cases, we may also have a legal obligation to collect personal information from you.If we ask you to provide personal information to comply with a legal requirement or to perform a contact with you, we will make this clear at the relevant time and advise you whether the provision of your personal information is mandatory or not (as well as of the possible consequences if you do not provide your personal information).
                <br></br>
                <br></br>

                Similarly, if we collect and use your personal information in reliance on our legitimate interests (or those of any third party), we will make clear to you at the relevant time why we need to use your personal information. If we process personal information in reliance on your consent, you may withdraw your consent at any time.If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under the “Contact Us” section below. Compliance with Laws and Law Enforcement Requests; Protection of Our Rights:In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We may disclose personal information to respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims. We may also share such information if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Service Agreement, or as otherwise required by law.Testimonials:From time to time, we may post testimonials on the Websites that may contain personal information. We obtain Your consent to post Your name along with Your testimonial. If you wish to update or delete your testimonial, you can contact us at support@dfu-solutions.com.
                <br></br>
                <br></br>

                ‍The Websites may offer publicly accessible blogs, community forums, comments sections, discussion forums, or other interactive features (“Interactive Areas“). You should be aware that any information that you post in an Interactive Area might be read, collected, and used by others who access it. To request removal of your personal information from an Interactive Area, contact us at support@dfu-solutions.com. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why. Notice concerning the party responsible for this website.The party responsible for processing data on this website is: Done-For-You Solutions LLC. Phone Number: +1 (817)-889-2359. Email: support@dfu-solutions.com If you are a resident of California, California law permits you to request certain information regarding the disclosure of your personal data by Done-For-You Solutions LLC., or its affiliates and subsidiaries, to a third-party for direct marketing purposes. To make such a request, please email us at support@dfu-solutions.com or write us at the address listed above.We respect children’s privacy in particular. We do not target knowingly or intentionally collect personally identifiable information from children under the age of 13. By using the Site, you have represented and warranted that you are either 18 years of age or using the Site with the supervision of a parent or guardian. If you are under 13, you may not use the Site. If you become aware that your child has provided us with personal information without your consent, you are urged to email us at support@dfu-solutions.com. If you would like to contact us for any reason regarding our privacy practices, please e-mail us at the following address: support@dfu-solutions.com. We may change this privacy policy at any time. If we make material changes to this privacy policy, we will prominently post that the policy has changed.</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};


