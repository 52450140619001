import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import { Layout } from './Layout';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import TermsPage from './routes/terms';
import Privacy from './routes/privacy';
import Disclaimer from './routes/disclaimer';
import DisclaimerPage from './routes/disclaimer';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "terms",
    element: <TermsPage />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "disclaimer",
    element: <DisclaimerPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Layout>
      <RouterProvider router={router} />

    </Layout>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
