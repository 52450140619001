'use client'

import { forwardRef, Suspense, useImperativeHandle, useRef } from 'react'
import { OrbitControls, PerspectiveCamera, View as ViewImpl } from '@react-three/drei'
import { Three } from './helpers/components/Three'

export const Common = ({ color }) => (
    <Suspense fallback={null}>
        {color && <color attach='background' args={[color]} />}
        <ambientLight intensity={0.8} />
        <pointLight position={[20, 30, 10]} intensity={5} decay={0} />
        {/* <pointLight position={[-10, -10, -10]} color='blue' decay={0.1} intensity={1} /> */}
    </Suspense>
)

const View = forwardRef(({ children, orbit, ...props }, ref) => {
    const localRef = useRef(null)
    useImperativeHandle(ref, () => localRef.current)

    return (
        <>
            <div ref={localRef} {...props} />
         
                <Three>
                    <ViewImpl track={localRef}>
                        {children}
                    </ViewImpl>
                </Three>
   
        </>
    )
})
View.displayName = 'View'

export { View }
