import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react';

import { DotLottiePlayer, Controls } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';
// import video1 from './testimonials/1.mp4';
// import video2 from './testimonials/2.mp4';
// import video3 from './testimonials/3.mp4';
import ReactPlayer from 'react-player';
const Play = ({ onPlayerClick }) => {
    return (
        <svg className="h-16 drop-shadow-lg" viewBox="0 0 60 60" >
            <polygon
                fill="white"
                points="0,0 50,30 0,60" />
        </svg>
    )
}


export function Video({ src, main }) {
    const [playing, setPlaying] = useState(true)
    const videoRef = useRef(null)
    const [neverPlayed, setNeverPlayed] = useState(true)
    const [muted, setMuted] = useState(true)
    const onVideoClicked = () => {
        clearInterval(intervalId)
        if (neverPlayed) {
            videoRef.current.seekTo(0)
            setMuted(false)
            setNeverPlayed(false)
            return
        }
        if (!playing) {
            // videoRef.current.play()
            setPlaying(true)
        } else {
            // videoRef.current.pause()
            setPlaying(false)
        }
    }
    useEffect(() => {
        if (main)
            console.log('Load');

        // pause
        // videoRef.current.pause()
        // setNeverPlayed(true)
    }, [src])

    const [intervalId, setIntervalId] = useState(null)



    useEffect(() => {
        if (!main || intervalId != null) return

        if (!neverPlayed) {
            console.log('Clear');
            clearInterval(intervalId)
            return
        }
        console.log('Never played', neverPlayed, main);
        // Only play first 3 seconds
        const intId = setInterval(() => {
            videoRef.current.seekTo(0)
        }, 5000)
        setIntervalId(intId)
    }, [neverPlayed])

    const wrapper = useRef()
    const video = useRef()

    return (
        <>
            <div className="relative min-h-44" style={{
                cursor: 'pointer'
            }}>
                {(neverPlayed || !playing) && <div className="pointer-events-none absolute z-10 flex h-full w-full items-center justify-center">
                    <Play />
                </div>}
                {main &&   <div className=" absolute bottom-0 flex w-full items-center justify-center rounded-b-md bg-gradient-to-r from-blue-500/50 to-cyan-500/50 py-3">
                      <p className="px-4 text-center text-[9px] font-bold text-white lg:text-sm">Ibraheem Abu-Tahnat <span className='font-light'>
                        | Done-For-You Solutions LLC
                      </span></p>

                    </div>}
                <div ref={wrapper}
                    onClick={onVideoClicked}
                    className={
                        playing && !neverPlayed ? 'block' : main ? '' : 'blur-sm'
                    }
                >

                    <ReactPlayer
                        url={src}
                        
                        playing={playing}
                        muted={muted}
                        controls={false}
                        ref={videoRef}
                        style={{
                            width: '100%',
                            backgroundColor: 'black',
                            padding: '0px',
                            height: '480px',
                            margin: '0px',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            zIndex: 1
                        }}
                        width="100%"
                        playsinline={true}
                        height="auto"
                    />
                </div>


            </div>
        </>
    )
}

const FiveStars = () => {
    return (
        <DotLottiePlayer

            src={'./5stars.json'}
            autoplay
            rendererSettings={{
                clearCanvas: true,
                context: '2d',
            }}
            loop
            renderer='canvas'
        >
        </DotLottiePlayer>
    )

}

export const TestimonialsSection = ({ ...props }) => {
    const openModal = () => {
        props.openModal();
    }
    const [hovered, hover] = useState(false)
    return (

        <div className=" relative bg-blue-500">

            <div className=" flex flex-col items-center justify-center py-12 text-center text-white">
                {/* Blue background with a wavy bottom edge */}
                <div className='w-40'>
                    <DotLottiePlayer
                        src={'./bluetick.json'}
                        autoplay
                        loop

                        renderer='canvas'
                        rendererSettings={{
                            clearCanvas: true,
                            context: '2d',
                        }}
                    >
                    </DotLottiePlayer>
                </div>
                <h2 className="mb-6 max-w-4xl text-5xl font-bold">SEE WHY OUR CLIENTS ARE LOVING <span className='font-extrabold text-gray-900'>
                    OUR ALGORITHM!</span></h2>
                <div className="absolute inset-0 w-full overflow-hidden" style={{ height: '200px' }}>

                </div>
            </div>

            {/* Testimonial cards */}
            <div className="mx-auto  grid max-w-7xl grid-cols-1 gap-4 px-4 text-black md:grid-cols-3">
                {/* Repeat this block for each testimonial */}
                <div className="flex flex-col items-center rounded-lg bg-white p-4 drop-shadow-lg">
                    <div className="mb-4 w-full rounded-t-lg bg-gray-300"> {

                        <Video src={'https://done-for-you.netlify.app/video/mp4/1.mp4'} ></Video>
                    } </div>
                    <div className="h-8 w-20 text-center text-sm">

                        <FiveStars />
                    </div>
                </div>
                <div className="flex flex-col items-center rounded-lg bg-white p-4 drop-shadow-lg">
                    <div className="mb-4 w-full rounded-t-lg bg-gray-300">                            <Video src={'https://done-for-you.netlify.app/video/mp4/2.mp4'} ></Video>
                    </div>
                    <div className="h-8 w-20 text-center text-sm">

                        <FiveStars />
                    </div>
                </div>
                <div className="flex flex-col items-center rounded-lg bg-white p-4 drop-shadow-lg">
                    <div className="mb-4  w-full rounded-t-lg bg-gray-300">                            <Video src={'https://done-for-you.netlify.app/video/mp4/3.mp4'} ></Video>
                    </div>
                    <div className="h-8 w-20">

                        <FiveStars />
                    </div>
                </div>
                {/* ... other testimonials */}
            </div>

            {/* CTA Button */}
            <motion.div
                animate={{
                    rotateZ: [0, -3, 0, 3, 0],
                    // infinite: Infinity,
                    transition: {
                        duration: 1,
                        repeat: Infinity,
                        repeatDelay: 2,
                        repeatType: "reverse",
                        ease: "linear",
                    },


                }}
                whileHover={{
                    rotateZ: 0,
                }}
                className="mt-12 text-center">
                <button type='button'
                    onPointerDown={openModal}

                    className="mx-4 mb-12  rounded-full bg-red-600 px-6 py-3 text-xl font-bold text-white transition duration-300 hover:bg-red-700">
                    YES, I WANT THE FREE TRAINING VIDEO
                </button>
            </motion.div>
        </div>
    );
};

