import logo from './logo.svg';
import './App.css';
import { View } from './components/View';
import { Suspense, useRef, useState } from 'react';
import { Environment } from '@react-three/drei';
import { Forex } from './components/Examples';
import trustpilotReviews from './img/trustpilot.png'

import { Canvas } from '@react-three/fiber';
import { TestimonialsSection, Video } from './testimonials';
import Footer from './footer';
import { PipedriveForm } from './Form';
import ReactModal from 'react-modal';
import youtubeLogo from './img/youtube.svg'

const Banner = () => {
  return (
    <div className=" w-auto rounded-lg p-4 text-center text-white  lg:mt-12">
      <h2 className="w-full text-[19px] font-normal leading-none tracking-wide  lg:text-[4.6vw]"
        style={{
          textShadow: '0px 0px 20px rgba(0,0,0,0.5)'
        }}
      >
        DISCOVER HOW OUR <span className="text-md text-blue-300">AI ALGORITHM </span> <span className='block w-full text-[25px] lg:text-[6vw]'>
          HELPED 100+ CLIENTS EARN
        </span>
      </h2>
      <div className="h-[50vh] touch-none"></div>
      <div


        className=" my-2
        inline-block w-full
        cursor-pointer rounded-2xl bg-gradient-to-r from-blue-500 to-cyan-500 px-4 py-2 font-light  "

      >
        <div className="l font-barlow text-2xl font-bold  -tracking-[2px] md:p-[2vw] md:text-[4.5vw]">
          $2,000-$10,000+ <span className="block md:inline-block">MONTHLY, 100% HANDS-OFF!</span>
        </div>
      </div>
    </div>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#001654',
    color: 'white'
  },
  overlay: {
    zIndex: 1000
  }
};



function App() {
  const [isClient, setIsClient] = useState(false)
  const [mouse, setMouse] = useState({
    x: !isClient ? -1 : window.innerWidth / 2,
    y: !isClient ? window.innerHeight / 2 : -1,
    deltaX: 0,
  })
  const [isMouseMoving, setIsMouseMoving] = useState(true);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }


  const app = useRef()
  const onMove = (e) => {
    setMouse({ x: e.clientX, y: e.clientY, deltaX: e.movementX });
  }

  const onTouchMove = (e) => {
    setMouse({ x: e.touches[0].clientX, y: e.touches[0].clientY, deltaX: e.touches[0].clientX < window.innerWidth / 2 ? -1 : 1 })
  }

  const src = 'https://done-for-you.netlify.app/video/mp4/video.mp4';

  const isMobileDevice = (typeof navigator !== 'undefined') ? navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) : false;
  return (
    <>

      <div className='font-sans'>
        <div className="absolute z-40 " ref={app} suppressHydrationWarning>
          <ReactModal
            isOpen={modalIsOpen}

            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={app.current}
            contentLabel="Example Modal"
          >
            <button className='mb-4 h-8 w-8 rounded-full border' onClick={closeModal}>x</button>
            <PipedriveForm mouseMove={(e) => {
              //
            }}
              popup={true}
              setMoving={(e) => {
                setIsMouseMoving(e)
              }}
            ></PipedriveForm>


          </ReactModal>
        </div>
        <div
          id="container"
          onTouchMove={onTouchMove}
          onTouchEnd={(e) => {

          }}
          onMouseMove={onMove}

          className='pointer-events-auto absolute left-0 top-0 z-10 m-0  flex h-full w-full  flex-col overflow-x-hidden overflow-y-scroll overscroll-none  p-0 text-white'>

          <div className="relative flex h-auto w-screen flex-col items-center justify-start lg:mb-12 ">
            <Banner></Banner>

            <div className="w-full p-8 lg:w-[80%]">
              <div className="flex flex-col gap-8 lg:flex-row ">
                {/* Video Section */}
                <div className="flex h-auto flex-1 flex-col items-center justify-start rounded-lg drop-shadow-lg">
                  <div className="relative mb-4 min-h-48 h-full flex h-auto w-full max-w-[500px] items-center justify-center rounded-md ">
                    {/* <img src={youtubeLogo} alt="youtube logo" /> */}

                    <div className="w-full min-h-48 h-full">
                      <Video main={true} src={src} ></Video>
                      <div className="pointer-events-none mt-4 flex items-center justify-center">
                    <img src={trustpilotReviews} draggable='false' className='w-2/4 select-none rounded-md' alt="trustpilot reviews" />
                  </div>
                    </div>
                  
                  </div>
           
                </div>

                <div className=" flex-1  rounded-lg p-4 ">
                  <h3 className="mb-4 text-center text-lg ">Fill In The Form To Access Your <span className=' font-bold text-blue-500'>FREE Strategy Video</span> Explaining Exactly How This Works...</h3>
                  {!isMobileDevice && <div className="w-full text-center text-xs">
                    Double click to select a field
                  </div>}
                  <PipedriveForm mouseMove={(e) => {
                    //
                  }}
                    popup={false}
                    setMoving={(e) => {
                      setIsMouseMoving(e)
                    }}
                  ></PipedriveForm>

                </div>
              </div>
            </div>
          </div>
          <div className="relative  w-screen ">
            <TestimonialsSection openModal={openModal}></TestimonialsSection>
            <Footer mouse={mouse}></Footer>
          </div>
        </div>

        <div className='relative'>

          <div id="canvas-container">
            <Canvas >
              <Suspense fallback={null}>
                <Environment preset='apartment' ></Environment>
                <Forex
                  resetMouse={() => { setMouse({ x: window.innerWidth / 2, y: window.innerHeight / 2, deltaX: 0 }) }}
                  mouse={mouse} scale={2} position={[0, -1.6, 0]} rotation={[0.0, -0.3, 0]} />
              </Suspense>
            </Canvas>
          </div>
        </div>
        <div className="hero absolute left-0 top-0 z-0 h-full w-full  bg-gradient-to-r from-cyan-950 to-blue-950">

        </div>

      </div>
    </>
  )
}


export default App;
